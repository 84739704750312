import React, { useContext } from "react";
import { Route, Redirect } from "react-router-dom";
import { AuthContext } from "../App/App";

export const ProtectedRouteComponent = ({
  isUserLoggedIn,
  component: Component,
  nodes,
  ...rest
}) => {
  const [authData] = useContext(AuthContext);

  const getProperComponent = (props) => {
    if (authData?.username) {
      return <Component {...rest} {...props} />;
    }

    return <Redirect to={`/login`} />;
  };

  return <Route {...rest} render={getProperComponent} />;
};

export default ProtectedRouteComponent;
