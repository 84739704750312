import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";

import ProtectedRoute from "../components/ProtectedRoute";
import Dashboard from "../components/Dashboard/Dashboard";
import Login from "../components/Login/Login";
import Spy from "../components/Spy/Spy";

const Routes = () => (
  <Switch>
    <Route path="/login" exact component={Login} />
    <ProtectedRoute exact path="/dashboard" component={Dashboard} />
    <ProtectedRoute exact path="/spy/:name" component={Spy} />
    <Redirect to="/login" />
  </Switch>
);

export default Routes;
