import { useEffect, useState, useCallback } from "react";
import { SpyAPI } from "../../lib/api/api";
import styled from "styled-components";
import { Layout, Spin, DatePicker, Button, Row, Col, Tag, Typography } from "antd";
import moment from "moment";
// eslint-disable-next-line no-unused-vars
import momentDurationFormatSetup from "moment-duration-format";

const { Content } = Layout;
const { RangePicker } = DatePicker;
const { Text } = Typography;

export default function Spy(props) {
  const spyid = props.match.params.name;
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [spy, setSpy] = useState([]);
  const [statTimestamp, setStartTimestamp] = useState(0);
  const [duration, setDuration] = useState(0);
  const [loading, setLoading] = useState(false);

  // Get spies
  useEffect(() => {
    SpyAPI.getSpy({ setSpy, setIsLoaded, setError, spyid });
  }, [spyid, setSpy, setIsLoaded, setError]);

  const handleOnChange = useCallback((data) => {
    if (!data) {
      setStartTimestamp(0);
      setDuration(0);
    }

    if (data && data.length > 0) {
      const startTimeOffset = data[0].utcOffset() * 60;
      const startDateTimestamp = moment(data[0]).unix() + startTimeOffset;
      const ednTimeOffset = data[1].utcOffset() * 60;
      const ednDateTimestamp = moment(data[1]).unix() + ednTimeOffset;

      setStartTimestamp(startDateTimestamp);
      setDuration(ednDateTimestamp - startDateTimestamp);
    }
  }, []);

  const handleDownload = useCallback(async () => {
    SpyAPI.downloadFile({ setLoading, statTimestamp, duration, spy });
  }, [duration, statTimestamp, spy, setLoading]);

  useEffect(() => {
    setDuration(0);
  }, [spy]);

  if (error) {
    return (
      <StyledContentCenter>
        <h1>There was an error. Please reload page!</h1>
      </StyledContentCenter>
    );
  }

  if (!isLoaded) {
    return (
      <StyledContentCenter>
        <Spin size="large" tip="Loading..." />
      </StyledContentCenter>
    );
  }

  const formatTimeDuration = moment.duration(duration, "seconds").format("h [h] m [m] s [s]");
  const timeRangeValidation = moment.duration(duration, "seconds").asHours() > 3;
  const disabledDate = (current) => {
    return current && current > moment().endOf("seconds");
  };

  function range(start, end) {
    const result = [];
    for (let i = start; i < end; i++) {
      result.push(i);
    }
    return result;
  }

  const disabledDateTime = (current) => {
    const day = moment().dayOfYear();
    const hour = moment().hour();
    const minutes = moment().minutes();
    const seconds = moment().seconds();
    const selectedDay = current && current.dayOfYear();
    const selectedHour = current && current.hour();
    const selectedMinutes = current && current.minutes();

    if (day !== selectedDay) {
      return null;
    }

    if (day === selectedDay && selectedHour !== hour) {
      return {
        disabledHours: () => range(0, 24).splice(hour + 1, 24 - hour),
      };
    }

    if (day === selectedDay && selectedHour === hour && selectedMinutes !== minutes) {
      return {
        disabledHours: () => range(0, 24).splice(hour + 1, 24 - hour),
        disabledMinutes: () => range(0, 60).splice(minutes + 1, 60 - minutes),
      };
    }

    return {
      disabledHours: () => range(0, 24).splice(hour + 1, 24 - hour),
      disabledMinutes: () => range(0, 60).splice(minutes + 1, 60 - minutes),
      disabledSeconds: () => range(0, 60).splice(seconds + 1, 60 - seconds),
    };
  };

  return (
    <Row justify="center" align="middle">
      <Col span={24} justify="center">
        <Row justify="center" gutter={[8, 12]}>
          <h1>{spy.spy}</h1>
          <Col span={24} justify="center" align="middle">
            <RangePicker
              size="large"
              showTime
              onChange={handleOnChange}
              disabledDate={disabledDate}
              disabledTime={disabledDateTime}
            />
          </Col>
          {timeRangeValidation && (
            <Col span={24} justify="center" align="middle">
              <StyledValidationInfo>Max time duration: 3 hours</StyledValidationInfo>
            </Col>
          )}
          <Col span={24} justify="center" align="middle">
            <span>
              Time duration: <Tag color="blue">{formatTimeDuration}</Tag>{" "}
            </span>
          </Col>
          {duration <= 0 && <Text type="secondary">Please select time range to download file</Text>}
          <Col span={24} justify="center" align="middle">
            <Button
              loading={loading}
              type="primary"
              onClick={handleDownload}
              disabled={duration <= 0 || timeRangeValidation || loading}
            >
              {loading ? <span>Downloading...</span> : <span>Download</span>}
            </Button>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}

const StyledValidationInfo = styled.span`
  color: #fc8888;
`;

const StyledContentCenter = styled(Content)`
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-items: center;
`;
