import React from "react";
import ReactDOM from "react-dom";
import App from "./components/App/App";
import styled from "styled-components";
import { Layout } from "antd";
import moment from "moment";

const { Header } = Layout;

const StyledH1 = styled.h1`
  color: rgba(255, 255, 255, 0.85);
`;

const StyledSpan = styled.span`
  color: rgba(255, 255, 255, 0.85);
`;

const FullHeightLayout = styled(Layout)`
  min-height: 100vh !important;
  display: flex;
  flex-direction: column;
`;

ReactDOM.render(
  <React.StrictMode>
    <FullHeightLayout>
      <Header>
        <StyledH1>4VOD Spy Downloader</StyledH1>
      </Header>
      <App />
      <Header>
        <StyledSpan>© 4VOD {moment().year()} - Adrian Grzeca</StyledSpan>
      </Header>
    </FullHeightLayout>
  </React.StrictMode>,
  document.getElementById("root")
);
