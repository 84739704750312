import React from 'react';
import styled from "styled-components";
import { Layout } from 'antd';

const { Content } = Layout;

export default function Dashboard() {
    return (
        <StyledContentCenter>
            <h1>Please select spy to download.</h1>
        </StyledContentCenter>
    );
}

const StyledContentCenter = styled(Content)`
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: center;
    align-items: center;
`;