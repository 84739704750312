import React, { useEffect, useState, createContext } from "react";
import { Link } from "react-router-dom";
import { Router } from "react-router";
import "./App.less";
import { message, Layout, Menu, Spin } from "antd";
import { VideoCameraOutlined, LogoutOutlined } from "@ant-design/icons";
import styled from "styled-components";
import { SpyAPI } from "../../lib/api";
import parseJwt from "../../lib/utils/parseJwt";

import Routes from "../../routes";
import history from "../../history";

export const AuthContext = createContext();
const { Content, Sider } = Layout;

function App() {
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(true);
  const [spies, setSpies] = useState([]);
  const [authData, setAuthData] = useState(null);

  useEffect(() => {
    const token = window.localStorage.getItem("authToken");

    const tokenData = parseJwt(token);
    setAuthData(tokenData);
  }, []);
  const isAuthorized = authData?.username;

  // Message config
  message.config({
    top: 125,
    duration: 5,
    maxCount: 1,
  });

  // Functions
  const logoutClick = function () {
    window.localStorage.removeItem("authToken");
    setAuthData(null);
  };

  // Get spies
  useEffect(() => {
    if (isAuthorized) {
      SpyAPI.getSpyList({ setSpies, setIsLoaded, setError });
    }
  }, [isAuthorized]);

  const dataLoaded = spies.length > 0;

  // Render
  if (error) {
    return (
      <StyledContentCenter>
        <h1>There was an error. Please reload page!</h1>
      </StyledContentCenter>
    );
  } else if (!isLoaded) {
    return (
      <StyledContentCenter>
        <Spin size="large" tip="Loading..." />
      </StyledContentCenter>
    );
  } else {
    return (
      <AuthContext.Provider value={[authData, setAuthData]}>
        <Router history={history}>
          <Layout>
            <Sider>
              {isAuthorized && dataLoaded && (
                <Menu theme="dark" mode="inline">
                  <StyledMenuItem key="0" icon={<LogoutOutlined />} onClick={() => logoutClick()}>
                    Logout
                  </StyledMenuItem>
                  {spies.map((spy) => (
                    <StyledMenuItem key={spy.id} icon={<VideoCameraOutlined />}>
                      <Link to={`/spy/${spy.id}`}>{spy.spy}</Link>
                    </StyledMenuItem>
                  ))}
                </Menu>
              )}
            </Sider>
            <StyledContent>
              <Routes />
            </StyledContent>
          </Layout>
        </Router>
      </AuthContext.Provider>
    );
  }
}

const StyledContent = styled(Content)`
  display: flex;
  justify-content: center;
`;

const StyledContentCenter = styled(Content)`
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-items: center;
`;

const StyledMenuItem = styled(Menu.Item)`
  margin-top: 0 !important;
`;

export default App;
