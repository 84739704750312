import React, { useContext } from "react";
import { Form, Input, Button, Card, Layout } from "antd";
import styled from "styled-components";
import { LockOutlined, UserOutlined } from "@ant-design/icons";
import { Redirect } from "react-router";

import { AuthAPI } from "../../lib/api/api";
import { AuthContext } from "../App/App";

const { Content } = Layout;

export default function Login() {
  const [authData, setAuthData] = useContext(AuthContext);

  const onFinish = async (formData) => {
    const data = {
      username: formData.username,
      password: formData.password,
    };
    AuthAPI.login({ data, setAuthData });
  };

  if (authData?.username) {
    return <Redirect to="/dashboard" />;
  }

  return (
    <StyledContent>
      <StyledFormContainer>
        <Card title="Log in">
          <StyledForm name="basic" onFinish={onFinish}>
            <Form.Item
              name="username"
              rules={[{ required: true, message: "Please input your username!" }]}
            >
              <Input
                prefix={<UserOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
                placeholder="Username"
              />
            </Form.Item>
            <Form.Item
              name="password"
              rules={[{ required: true, message: "Please input your password!" }]}
            >
              <Input.Password
                prefix={<LockOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
                type="password"
                placeholder="Password"
              />
            </Form.Item>
            <Form.Item>
              <StyledButton type="primary" htmlType="submit">
                Log In
              </StyledButton>
            </Form.Item>
          </StyledForm>
        </Card>
      </StyledFormContainer>
    </StyledContent>
  );
}

const StyledFormContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-items: center;
`;

const StyledButton = styled(Button)`
  display: block;
  width: 100%;
  justify-content: center;
  margin: 20px 0 0 0;
`;

const StyledForm = styled(Form)`
  min-width: 400px;
`;

const StyledContent = styled(Content)`
  display: flex;
`;
