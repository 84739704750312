import moment from "moment";
import { message } from "antd";
import { camelizeKeys } from "humps";
import { saveAs } from "file-saver";

import history from "../../history";
import parseJwt from "../utils/parseJwt";

export const buildUrl = (path) => {
  const API_ROOT = process.env.REACT_APP_API_URL;
  const API_PORT = process.env.REACT_APP_API_PORT;

  return path.indexOf(API_ROOT) === -1 ? `${API_ROOT}:${API_PORT}${path}` : path;
};

const headers = {
  Accept: "application/json",
  "Content-Type": "application/json",
};

function checkTokenExpireDate(token) {
  if (!token && window && window.exp) return false;

  const parsedToken = parseJwt(token);
  const tokenExpTimestamp = parsedToken.exp;

  const expireSoon = moment().add(2, "hours").utc().unix();

  return tokenExpTimestamp < expireSoon;
}

function tokenIsActive(token) {
  if (!token) return false;

  const parsedToken = parseJwt(token);

  const tokenExpTimestamp = parsedToken.exp;
  const currentTimestamp = moment().utc().unix();

  if (window && window.exp) return false;

  return tokenExpTimestamp > currentTimestamp;
}

function logoutUser() {
  message.error("User was logged out");
  history.push("/login");
  window.localStorage.removeItem("authToken");
}

const request = async ({ method, path, body }) => {
  const url = path.includes("http") ? path : buildUrl(path);
  const token = window.localStorage.getItem("authToken");

  const active = tokenIsActive(token);

  if (token && !active) {
    logoutUser();

    return Promise.reject(
      new Error("Your session expired or you do not have access for this resources.")
    );
  }

  if (token && active) {
    headers["x-access-token"] = token;
  } else {
    delete headers.Authorization;
  }

  const response = await fetch(url, {
    method,
    headers,
    body: JSON.stringify(body),
  });

  const tokenExpireSoon = checkTokenExpireDate(token);

  if (tokenExpireSoon) {
    AuthAPI.rotateToken();
  }

  const json = await response.json();

  if (!response.ok) {
    // eslint-disable-next-line prefer-promise-reject-errors
    return Promise.reject({
      ...json,
      statusCode: response.status, // statusCode is deprecated.
      status: response.status,
    });
  }

  return camelizeKeys(json);
};

export const get = (path) => request({ method: "GET", path });
export const post = (path, body) => request({ method: "POST", path, body });
export const patch = (path, body) => request({ method: "PATCH", path, body });
export const put = (path, body) => request({ method: "PUT", path, body });
export const destroy = (path) => request({ method: "DELETE", path });

export class AuthAPI {
  static HOST = process.env.REACT_APP_API_URL;

  static PORT = process.env.REACT_APP_API_PORT;

  static login({ data, header, setAuthData }) {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await fetch(`${this.HOST}:${this.PORT}/login`, {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            ...header,
          },
          body: JSON.stringify(data),
        });

        // Response error handler
        if (!response.ok) {
          if (response.status === 400 || response.status === 401) {
            message.error("Invalid login and password.");
          }

          if (response.status === 500) {
            history.replace("/login");
            message.error("There is problem. Please contact with support.");
          }
        }

        const json = await response.json();

        try {
          const decodedToken = parseJwt(json.token);
          window.localStorage.setItem("authToken", json.token);
          message.success("Log in succesfully!");
          setAuthData(decodedToken);
          history.push("/dashboard");
        } catch (err) {
          console.log("err", err);
          history.replace("/login");
          message.error("There is problem. Please contact with support.");
        }

        resolve(json);
      } catch (error) {
        console.log("error", error);

        reject(error);
      }
    });
  }

  static async rotateToken() {
    try {
      const data = await post(`${this.HOST}:${this.PORT}/rotate`);
      window.localStorage.setItem("authToken", data.token);
    } catch (err) {
      message.error("Session has expired");
      history.replace("/login");
    }
  }
}

export class SpyAPI {
  static HOST = process.env.REACT_APP_API_URL;
  static PORT = process.env.REACT_APP_API_PORT;

  static async getSpyList({ setSpies, setIsLoaded, setError }) {
    try {
      setIsLoaded(false);
      const data = await get(`${this.HOST}:${this.PORT}/spy`);
      setSpies(data.spy);
      setIsLoaded(true);
    } catch (error) {
      setError(error);
      setIsLoaded(true);
      history.replace("/login");
      message.error("There is problem. Please contact with support.");
    }
  }

  static async getSpy({ setSpy, setIsLoaded, setError, spyid }) {
    try {
      setIsLoaded(false);
      const data = await post(`${this.HOST}:${this.PORT}/getspy`, { spy: spyid });

      setSpy(data);
      setIsLoaded(true);
    } catch (error) {
      setError(error);
      setIsLoaded(true);
      history.replace("/login");
      message.error("There is problem. Please contact with support.");
    }
  }

  static downloadFile({ setLoading, statTimestamp, duration, spy }) {
    setLoading(true);
    message.info(
      "Save file window will be displayed after the download is complete, please wait.",
      4
    );
    fetch(`${spy.apiUrl}/archive-${statTimestamp}-${duration}.mp4`)
      .then((response) => {
        if (response.status !== 200) {
          message.error("No data for this time range", 3);
          setLoading(false);

          return;
        }

        return response.blob();
      })
      .then((blob) => {
        setLoading(false);

        if (blob) {
          saveAs(blob, `${spy.spy}-${statTimestamp}-${duration}.mp4`);
        }
      });
  }
}
